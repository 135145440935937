<template>
  <full-view title="Product">
    <product-card :item="getItem" open show-rating show-description show-tags />
  </full-view>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FullView from "@/common/layouts/FullView";
import ProductCard from "@/views/stores/ProductCard";

export default {
  name: "OpenProduct",
  components: { FullView, ProductCard },
  mounted() {
    if (!this.getItem) {
      this.loadStoreProduct(this.getProductIdParam);
    }
    this.setReactivityParams({
      resource: "PRODUCTS",
      scope: "store/product",
      key: `${this.getStoreIdParam}/${this.getProductIdParam}`,
    });
    this.$sub("reactivity/PRODUCTS", (payload) => {
      this.loading = true;
      if (payload.mode !== "Delete") {
        this.loadStoreProduct(this.getProductIdParam);
      } else {
        this.removeStoreProductsFromState(payload.product).then(
          () => (this.loading = false)
        );
        this.$popup("product.messages.productNoLongerAvailable", "info");
      }
      console.log("reactivity/PRODUCTS:", payload);
    });
  },
  beforeDestroy() {
    this.$unsubAll();
    this.clearReactivityParams();
  },
  computed: {
    ...mapGetters("products", ["getStoreProductById"]),

    getItem() {
      return this.getStoreProductById(this.getProductIdParam);
    },
    getProductIdParam() {
      return parseInt(this.$route.params.productId);
    },
  },
  methods: {
    ...mapActions("products", ["loadStoreProduct", "removeStoreProductsFromState"]),
    ...mapActions("ui", ["setReactivityParams", "clearReactivityParams"]),
    ...mapActions("categories", ["refreshProducts"]),
  },
};
</script>
